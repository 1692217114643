export const preset = 'toclqyod';
export const cloud = 'https://api.cloudinary.com/v1_1/ddkv32qqz/image/upload';

export const paymentTypes = [
  { value: 'Gold' },
  { value: 'Cash' },
  { value: 'Credit Card' },
  { value: 'Online' },
  { value: 'Cheque' },
];

// export const BASE_URL = 'http://localhost:3500/api/v1/';
// export const IMG_URL = 'http://localhost:3500';

export const BASE_URL = 'https://anugra.gurkhawatch.com/api/v1/';
export const IMG_URL = 'https://anugra.gurkhawatch.com';
